import React, { useState, useEffect } from "react";
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import './App.css';
import Login from "./components/Login";
import SmartChat from './components/SmartChat';

import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "./config/msal/graph";
import { loginRequest } from "./config/msal/authConfig";

import { useSelector, useDispatch } from 'react-redux';
import { setUserProfile } from "./redux/actions/authActions";
import axios from "axios";
import SessionTimeout from "./components/SessionTimeout";
import PageNotFound from "./components/PageNotFound"
import { ConfigProvider, Space } from "antd";
import { setEnv, setTabId, setThemeColor } from "./redux/actions/chatActions";

function App() {
  const [accessToken, setAccessToken] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [authInProgress, setAuthInProgress] = useState(false);
  const [pageId, setPageId] = useState(null);

  const {
    auth: {
      isAuthenticated,
      user
    }
  } = useSelector(state => state);

  const {
    tabId,
    themeColor
  } = useSelector(state => state.chat);

  const dispatch = useDispatch();
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    setAuthInProgress(true);
    const getUserProfile = async () => {
      try {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        }).then((response) => {
          // console.log('<<<<<<response<<<<<' + JSON.stringify(response))
          setAccessToken(response.accessToken);
          // setTokenExpiresOn(response.expiresOn);
          callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
      } catch (error) {
        setAuthInProgress(false);
      }
    }
    getUserProfile();
  }, [instance, inProgress, accounts])

  useEffect(() => {
    const checkUserAccess = async () => {
      try {

      } catch (error) {

      }
      if (graphData) {

        // console.log('<<<<<<graphData<<<<<' + JSON.stringify(graphData))
        // console.log('<<<<<<<<<<<<<accounts<<<<<<<' + JSON.stringify(accounts))
        // console.log('<<<<<<<<<<<<<graphdata<<<<<<<' + JSON.stringify(graphData))
        const { displayName, userPrincipalName, givenName, surname } = graphData;

        const graphEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";


        let userAvatar = null;
        window.URL.revokeObjectURL(userAvatar)
        try {
          const response = await axios(graphEndpoint, {
            headers: { Authorization: `Bearer ${accessToken}`, },
            responseType: "blob"
          })

          if (response.status === 200)
            userAvatar = window.URL.createObjectURL(response.data);

        } catch (error) {
          userAvatar = null;
        }

        const userProfile = {
          isAuthenticated: true,
          user: {
            fullname: displayName,
            email: userPrincipalName,
            firstname: givenName,
            lastname: surname,
            avatar: userAvatar
          },
          token: accessToken
        }

        //window.URL.revokeObjectURL(userAvtar)
        dispatch(setUserProfile(userProfile))
        setAuthInProgress(false);

        const queryParams = new URLSearchParams(window.location.search.toLowerCase());
        // Correct code commented out. temporary solution in place until whole app is prod ready.
        // const id = queryParams.get('id');
        // const id = queryParams.get('id') || 1;
        // const activeTab = queryParams.get('activetab');

        // setPageId(id);

        // if (id <= 3 && id !== '0') {
        //   dispatch(setTabId(id ? id : 1));
        // }
        // else {
        //   dispatch(setTabId(id));
        // } else {
        //   dispatch(setTabId(activeTab ? activeTab : 1));
        // }
        let id = queryParams.get('id');
        // If id contains any non-numeric characters, set it to 1
        // if (id && !/^\d+$/.test(id)) {
        //   id = '1';
        // }
        // else if (!id) {
        //   id = '1';
        // }
        const activeTab = queryParams.get('activetab');

        setPageId(id);

        if (id >= '1' && id <= '3') {
          dispatch(setTabId(id));
      } else {
        if (Number(activeTab) >= 1 && Number(activeTab) <= 3) {
              dispatch(setTabId(activeTab));
          } else {
              dispatch(setTabId('1'));
          }
      }
      }
      else {
        //setHasAccess(false)
        setAuthInProgress(false);
      }
    }
    checkUserAccess();
  }, [graphData]);

  useEffect(() => {
    const setEnvID = () => {
      let reposSetID, contextID;
      if (tabId == 2) {
        reposSetID = process.env.REACT_APP_PROCUREMENT_REPOSET_ID;
        contextID = process.env.REACT_APP_PROCUREMENT_CONTEXT_ID;
      } else if (tabId == 3) {
        reposSetID = process.env.REACT_APP_OPEX_REPOSET_ID;
        contextID = process.env.REACT_APP_OPEX_CONTEXT_ID;
      } else {
        reposSetID = process.env.REACT_APP_IT_REPOSET_ID;
        contextID = process.env.REACT_APP_IT_CONTEXT_ID;
      }
      dispatch(setEnv({
        UserEmail: user.email,
        ReposSetID: reposSetID,
        ContextID: contextID,
      })).then(() => {
        getThemeColor(tabId);
      }).catch(() => {
        getThemeColor(tabId);
      });
    };
    if (tabId !== null) {
      setEnvID();
    }
  }, [tabId]);

  const getThemeColor = (tabid) => {
    const colors = {
      '1': '#8764B8',
      '2': '#349eff',
      '3': '#E4001C',
    };

    dispatch(setThemeColor(colors[tabid] || ''));
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: themeColor,
          colorInfo: themeColor
        }
      }}
    >
      <div className="App">

        <div className="chat-window"  style={{minHeight:547, width : (window.self !== window.top ? '100%' : 371)}}>
          <div className="triangle" />
          <div className="inner-chat-window">
            <div className="chat-nav" style={{ alignItems: 'center' }}>
              <a href="https://dupont.sharepoint.com/sites/IntranetV2" className="myRed-link" target="_blank">&lt; Visit My<b>Red</b></a>
              <h1 className="chat-header"><a href='https://dupont.sharepoint.com/sites/ITMatters/SitePages/Generative-AI-at-DuPont.aspx?xsdata=MDV8MDJ8fDcyZWNiNTBlZGJhMzRkN2Y2NGYzMDhkYzFiNjY4YjcwfDZjNTY5N2NjMWVjZTRjNDg4ZTNiN2MzNzljMDJhYWI2fDB8MHw2Mzg0MTUzNzQ0MTk3ODA4NTF8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPbTFsWlhScGJtZGZUV3BhYTA5SFVYZGFWRUYwV1ZSWmQwMVRNREJQUkdONFRGZEZlbHBxVlhSYWFteHJXbFJaZVZwcVNUQlpiVWt5UUhSb2NtVmhaQzUyTWk5dFpYTnpZV2RsY3k4eE56QTFPVFF3TmpReE1EZzB8ZmM5Y2U4ZjVmNDgzNDNlOTAyYTYwOGRjMWI2NjhiNmV8Mzg5NDU2ODZkZjNkNDRkN2I2M2ZkZmM0ZWNmYjBkODU%3D&sdata=L25kdW1KbU42eVhZNm84QUJSUGpPeE5WMkhNN21aNnNoS285eUFlQTZyST0%3D&ovuser=6c5697cc-1ece-4c48-8e3b-7c379c02aab6%2Cvincent.sayseng%40dupont.com#:~:text=What%27s%20inside%20the%20IT%20Knowledge%20Finder%3F' target="_blank" style={{ color: '#fff', fontSize: 12, textDecoration: 'none', fontWeight: 'normal', verticalAlign: 'middle' }}>About</a></h1>
            </div>

            {!authInProgress &&
              <Router>
                {!isAuthenticated && <Login />}
                {isAuthenticated && <>
                  <SessionTimeout />
                  <Routes>
                    <Route exact path='/' element={<SmartChat pageId={pageId} />}>
                      <Route path='/page' element={<SmartChat pageId={pageId} />} />
                      {/* <Route path='/tab/:id/:activeTab' element={<SmartChat />} /> */}
                    </Route>
                    <Route path="*" element={<PageNotFound />} /> {/* Wildcard route */}
                  </Routes>
                </>
                }
              </Router>
            }


          </div>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default App;